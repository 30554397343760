import React from 'react'

export default function Subsidiaries() {
  return (
    <div className='subsidiaries' id="subsidiaries"> 
        <h1>Sub<span>sidiaries</span></h1>
        <div className='content'>
                <p>Coming Soon</p>
        </div>
        </div>
  )
}
